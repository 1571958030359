.form-group {
    margin-bottom: 0.8rem !important;
}
.react-datepicker-wrapper {
    display: block !important;
}
/* data table style */
.MuiTableCell-root {
    padding: 6px 16px !important;
    font-size: 0.875rem i !important;
}
.MuiTableCell-head th{
    font-weight: 700;
}
.MuiButton-root {
    font-weight: 600 !important;
}

/* button style */
.btn {
    padding: 0.3rem 0.7rem !important;
    font-size: 0.81rem !important;
    line-height: 1.2 !important;
}
hr {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.MuiIconButton-root {
    color: #FF8C00 !important;
}
/* Dashboard Style */
.dashboard-header{
    color: #FF8C00 !important;
    text-transform: uppercase;
}

/* Card Style */
.card-header {
    font-weight: 700;
    padding: 0.6rem 1.25rem !important;
}

/* logo style */
.c-sidebar-brand {
    justify-content: left !important;
}
.c-sidebar-brand img {
    margin-left: 30px;
}
.c-header.c-header-fixed{
    position: relative !important;
}
.c-sidebar-nav-dropdown-toggle:hover {
    background: #df7d51 !important;
}

.c-sidebar-nav-link:hover{
    background: #df7d51 !important;
}

.MuiTableCell-head{
    z-index: 0 !important;
}

.DraftEditor-editorContainer{
    z-index: 0 !important;
}

.jumbotron{
    padding: 1rem 1rem !important;
    margin-bottom: 0rem !important;
}
.text-value {
    font-size: 1.2rem !important;
}

[class^=text-value] {
    font-weight: 700 !important;
}
.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
    background: #ff8c00 !important;
}
.c-avatar-img .sb-avatar__text{
    color: #F47036 !important;
    background: rgb(255 255 255) !important;
}
.c-sidebar .c-sidebar-brand {
    background: #ffff01 !important;
}